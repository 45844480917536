import { useEffect } from "react"

import { useToken } from "../contexts/Token"

import { useNavigate } from "react-router-dom"

const Home = () => {
    const token = useToken()
    const navigate = useNavigate()

    useEffect(() => {
        navigate(token.access ? "/data-usage" : "/login")
        // eslint-disable-next-line
    }, [])

    return null
}

export default Home