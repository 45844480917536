import { useEffect, useReducer } from "react"
import { NavLink } from "react-router-dom"
import { BsClockHistory } from "react-icons/bs"

import { useToken } from "../contexts/Token"
import { usePopup } from "../contexts/Popup"

import ErrorPopup from "../components/ErrorPopup"

import { useLocation, useNavigate } from "react-router-dom"
import DataUsageSkeleton from "../components/DataUsageSkeleton"
import Pagination from "../components/Pagination"

const DataUsage = () => {
    const [listData, dispatch] = useReducer(listDataReducer, initialListData)

    const token = useToken()
    const popup = usePopup()
    const location = useLocation()
    const navigate = useNavigate()

    const getList = async () => {
        dispatch({ action: "startProcessing" })

        try {
            const response = await token.axiosJWT.get(`${process.env.REACT_APP_API_URL}/admin/get_data_usage?search=${listData.search}&page=${listData.page}`)
            console.log(response)
            dispatch({ action: "setList", list: response.data.data, totalPage: response.data.total_page })
            dispatch({ action: "stopProcessing" })
        } catch (error) {
            dispatch({ action: "stopProcessing" })
            popup.add(<ErrorPopup message={!error.response ? error.message : error.response.data} />)
        }
    }

    useEffect(() => {
        getList()
        // eslint-disable-next-line
    }, [listData.search, listData.page])

    useEffect(() => {
        const urlParams = new URLSearchParams(location.search)
        const search = urlParams.get("search") ? urlParams.get("search") : ""
        const page = urlParams.get("page") ? urlParams.get("page") : 1

        dispatch({ action: "setFilters", search, page, searchTemp: search })
        // eslint-disable-next-line
    }, [location.search])

    const search = (event) => {
        if (event.key === "Enter") navigate(`/data-usage?search=${listData.searchTemp}&page=1`)
    }

    const changePage = (page) => {
        navigate(`/data-usage?search=${listData.search}&page=${page}`)
    }

    const formatDate = (date) => {
        const data = new Date(date)
        const bulan = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]

        return `${data.getDate()} ${bulan[parseInt(data.getMonth())]} ${data.getFullYear()} ${data.getHours()}:${data.getMinutes()}:${data.getSeconds()}`
    }

    return (
        <>
            <div className="flex gap-[10px] flex-wrap p-[10px] sm:py-[20px] sm:px-[20px] xl:px-0">
                <div className="flex gap-[10px]">
                    <input type="text" onKeyUp={search} value={listData.searchTemp} onChange={event => dispatch({ action: "setSearchTemp", value: event.target.value })} placeholder="Search" className="block border w-full h-[40px] px-[15px] rounded-[6px] sm:w-[250px]" />
                    {/* <a href="http://203.194.114.191:8080/" target="_blank" rel="noreferrer" className="bg-teal-700 text-white m-auto h-[40px] rounded-[6px] leading-[40px] px-[15px] flex items-center gap-[10px]">Dashboard RTMP <BsBoxArrowRight /></a> */}
                </div>
            </div>
            <table className="w-full sm:mb-[80px]">
                <thead className="hidden sm:table-header-group">
                    <tr>
                        <th className="border h-[40px] bg-gray-300 w-[300px]">User ID</th>
                        <th className="border h-[40px] bg-gray-300">Username</th>
                        <th className="border h-[40px] bg-gray-300 w-[150px]">Data Usage</th>
                        <th className="border h-[40px] bg-gray-300 w-[150px]">Data Saved</th>
                        <th className="border h-[40px] bg-gray-300 w-[200px]">Last Update</th>
                        <th className="border h-[40px] bg-gray-300 w-[200px]">Detail</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        listData.processing
                            ? <DataUsageSkeleton />
                            : (
                                listData.list.length ?
                                    (
                                        listData.list.map(item => (
                                            <tr key={item.id} className="grid gird-cols-[auto_auto] bg-white w-full mb-[20px] border-b sm:table-row sm:border-b-0">
                                                <td className="order-1 border col-span-2 p-[10px] before:content-['User_ID_:'] before:font-bold before:block sm:p-[15px] sm:before:hidden">
                                                    {item.id}
                                                </td>
                                                <td className="order-2 col-span-2 border p-[10px] before:content-['Username_:'] before:font-bold before:mr-1 sm:p-[15px] sm:before:hidden">
                                                    {item.username}
                                                </td>
                                                <td className="order-4 border col-span-2 p-[10px] before:content-['Data_usage_:'] before:font-bold sm:p-[15px] sm:before:hidden">
                                                    <div className="pl-[5px] px-[10px] font-bold text-orange-500 inline">
                                                        {item.data_usage_formatted}
                                                    </div>
                                                </td>
                                                <td className="order-5 border col-span-2 p-[10px] before:content-['Data_saved:'] before:font-bold sm:p-[15px] sm:before:hidden">
                                                    <div className="pl-[5px] font-bold text-teal-700 inline">
                                                        {item.data_saved_formatted}
                                                    </div>
                                                </td>
                                                <td className="order-3 col-span-2 border p-[10px] before:content-['Last_update_:'] before:font-bold before:mr-1 sm:p-[15px] sm:before:hidden">
                                                    {formatDate(item.updatedAt)}
                                                </td>
                                                <td className="order-6 col-span-2 border p-[10px] before:font-bold sm:p-[15px] sm:before:hidden">
                                                    <NavLink to={''}>
                                                        <div className="bg-sky-600 px-[10px] h-[30px] border border-sky-600 rounded-[6px] text-white flex items-center justify-center gap-[10px]">
                                                            <BsClockHistory className="w-[13px]"/> Usage History
                                                        </div>
                                                    </NavLink>
                                                </td>
                                            </tr>
                                        ))
                                    )
                                    :
                                    <tr>
                                        <td colSpan="5" className="h-[40px] border text-center">
                                            empty data
                                        </td>
                                    </tr>
                            )
                    }
                </tbody >
            </table >
            <Pagination totalPage={listData.totalPage} page={listData.page} changePage={changePage} />
        </>
    )
}

export default DataUsage

const listDataReducer = (listData, payload) => {
    switch (payload.action) {
        case "startProcessing": return { ...listData, processing: true }
        case "stopProcessing": return { ...listData, processing: false }
        case "setList": return { ...listData, list: payload.list, totalPage: payload.totalPage }
        case "setFilters": return { ...listData, search: payload.search, page: payload.page }
        case "setSearchTemp": return { ...listData, searchTemp: payload.value }
        default: throw Error("Unknown listDataReducer action : " + payload.action)
    }
}

const urlParams = new URLSearchParams(window.location.search)

const initialListData = {
    list: [],
    totalPage: 1,
    search: urlParams.get("search") ? urlParams.get("search") : "",
    page: urlParams.get("page") ? urlParams.get("page") : 1,
    processing: false,
    searchTemp: urlParams.get("search") ? urlParams.get("search") : ""
}