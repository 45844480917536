import TokenProvider from "./contexts/Token"
import PopupProvider from "./contexts/Popup"

import NotLoggedIn from "./layouts/NotLoggedIn"
import LoggedIn from "./layouts/LoggedIn"
import PageNotFound from "./pages/PageNotFound"
import Home from "./pages/Home"
import Login from "./pages/Login"
import DataUsage from "./pages/DataUsage"

import { BrowserRouter, Routes, Route } from "react-router-dom"

function App() {
  return (
    <BrowserRouter>
      <TokenProvider>
        <PopupProvider>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<NotLoggedIn><Login /></NotLoggedIn>} />
            <Route path="/data-usage" element={<LoggedIn><DataUsage /></LoggedIn>} />
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </PopupProvider>
      </TokenProvider>
    </BrowserRouter>
  )
}

export default App;
