const DataUsageSkeleton = () => {
    return (
        <>
            <tr>
                <td className="border h-[40px] px-[15px]">
                    <div className="h-[25px] w-[210px] rounded-[6px] skeleton"></div>
                </td>
                <td className="border h-[40px] px-[15px]">
                    <div className="h-[25px] w-[320px] rounded-[6px] skeleton"></div>
                </td>
                <td className="border h-[40px] px-[15px]">
                    <div className="h-[25px] w-[200px] rounded-[6px] skeleton"></div>
                </td>
                <td className="border h-[40px] px-[15px]">
                    <div className="h-[25px] w-[200px] rounded-[6px] skeleton"></div>
                </td>
                <td className="border h-[40px] px-[15px]">
                    <div className="h-[25px] w-[100px] rounded-[6px] skeleton"></div>
                </td>
            </tr>

            <tr>
                <td className="border h-[40px] px-[15px]">
                    <div className="h-[25px] w-[210px] rounded-[6px] skeleton"></div>
                </td>
                <td className="border h-[40px] px-[15px]">
                    <div className="h-[25px] w-[320px] rounded-[6px] skeleton"></div>
                </td>
                <td className="border h-[40px] px-[15px]">
                    <div className="h-[25px] w-[200px] rounded-[6px] skeleton"></div>
                </td>
                <td className="border h-[40px] px-[15px]">
                    <div className="h-[25px] w-[200px] rounded-[6px] skeleton"></div>
                </td>
                <td className="border h-[40px] px-[15px]">
                    <div className="h-[25px] w-[100px] rounded-[6px] skeleton"></div>
                </td>
            </tr>

            <tr>
                <td className="border h-[40px] px-[15px]">
                    <div className="h-[25px] w-[210px] rounded-[6px] skeleton"></div>
                </td>
                <td className="border h-[40px] px-[15px]">
                    <div className="h-[25px] w-[320px] rounded-[6px] skeleton"></div>
                </td>
                <td className="border h-[40px] px-[15px]">
                    <div className="h-[25px] w-[200px] rounded-[6px] skeleton"></div>
                </td>
                <td className="border h-[40px] px-[15px]">
                    <div className="h-[25px] w-[200px] rounded-[6px] skeleton"></div>
                </td>
                <td className="border h-[40px] px-[15px]">
                    <div className="h-[25px] w-[100px] rounded-[6px] skeleton"></div>
                </td>
            </tr>

            <tr>
                <td className="border h-[40px] px-[15px]">
                    <div className="h-[25px] w-[210px] rounded-[6px] skeleton"></div>
                </td>
                <td className="border h-[40px] px-[15px]">
                    <div className="h-[25px] w-[320px] rounded-[6px] skeleton"></div>
                </td>
                <td className="border h-[40px] px-[15px]">
                    <div className="h-[25px] w-[200px] rounded-[6px] skeleton"></div>
                </td>
                <td className="border h-[40px] px-[15px]">
                    <div className="h-[25px] w-[200px] rounded-[6px] skeleton"></div>
                </td>
                <td className="border h-[40px] px-[15px]">
                    <div className="h-[25px] w-[100px] rounded-[6px] skeleton"></div>
                </td>
            </tr>
        </>
    )
}

export default DataUsageSkeleton