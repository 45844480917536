import axios from "axios"
import { NavLink, useLocation, useNavigate } from "react-router-dom"
import { useToken } from "../contexts/Token"

const Navbar = ({ active, setActive }) => {
    const pathname = useLocation().pathname
    const token = useToken()
    const navigate = useNavigate()

    const logout = async () => {
        try {
            await axios.delete(process.env.REACT_APP_API_URL + "/admin/logout")
            token.reset()
            navigate("/login")
        } catch (error) {
            console.log(error)
        }
    }

    const deactivateMenu = () => {
        setActive(false)
    }

    return (
        <nav onClick={deactivateMenu} className={`bg-gray-950/60 fixed w-full h-full left-0 top-0 transition-all ${active ? "pl-[20%] visible" : "invisible pl-[100%]"} sm:visible sm:p-0 sm:relative sm:block sm:w-max sm:transition-none`}>
            <ul className='bg-white absolute h-full w-[80%] sm:relative sm:w-max sm:flex sm:flex-row sm:items-center sm:h-full sm:justify-between sm:px-0 sm:gap-[30px]'>
                <li>
                    <NavLink to="/data-usage">
                        <span className={`border-b block p-[10px] ${pathname === "/data-usage" ? "font-bold text-teal-700" : null} sm:border-none sm:p-0`}>Data Usage</span>
                    </NavLink>
                </li>
                <li>
                    <button onClick={logout} className="border-b block p-[10px] w-full text-left sm:border sm:p-0 sm:text-teal-700 sm:border-teal-700 sm:px-[15px] sm:h-[40px] sm:rounded-[6px]">
                        Log Out
                    </button>
                </li>
            </ul>
        </nav >
    )
}

export default Navbar