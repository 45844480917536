import { useEffect, useReducer, useRef } from "react"

import { useToken } from "../contexts/Token"
import logoPusIntelAD from "../assets/logo-pushintelad.png"

import axios from "axios"
import { useNavigate } from "react-router-dom"
import { BsX } from "react-icons/bs"

const Login = () => {
    const [dataForm, dispatch] = useReducer(dataFormReducer, initialDataForm)
    const navigate = useNavigate()
    const token = useToken()

    const refUsername = useRef()
    const refPassword = useRef()

    const removeError = (event) => {
        if (event.target.value) dispatch({ action: "deleteSingleErrorMessage", part: event.target.name })
    }

    const removeErrorOther = () => {
        dispatch({ action: "deleteSingleErrorMessage", part: "other" })
    }

    useEffect(() => {
        if (dataForm.errorMessage.other) setTimeout(() => {
            removeErrorOther()
        }, 10000);
    }, [dataForm.errorMessage.other])

    const login = async (event) => {
        event.preventDefault()

        dispatch({ action: "startProcessing" })

        let error = {}
        if (!refUsername.current.value) error.username = "username should not be empty"
        if (!refPassword.current.value) error.password = "password should not be empty"

        if (Object.keys(error).length) {
            dispatch({ action: "stopProcessing" })
            return dispatch({ action: "addErrorMessage", value: error })
        }

        try {
            const response = await axios.post(process.env.REACT_APP_API_URL + "/admin/login", {
                username: refUsername.current.value,
                password: refPassword.current.value,
            })
            dispatch({ action: "stopProcessing" })
            token.setAccessToken(response.data.access_token)
            token.setRefreshToken(response.data.refresh_token)
            navigate("/data-usage")
        } catch (error) {
            dispatch({ action: "stopProcessing" })
            if (!error.response) return dispatch({ action: "addErrorMessage", value: { other: error.message } })
            if (!error.response.data.message) return dispatch({ action: "addErrorMessage", value: { other: error.response.data } })
            dispatch({ action: "addErrorMessage", value: error.response.data.message })
        }
    }

    return (
        <main className="w-full py-[20px] grid place-content-center min-h-screen">
            <img src={logoPusIntelAD} alt="Logo PUSINTELAD" className="h-[100px] m-auto" />
            {/* <a href="http://203.194.114.191:8080/" target="_blank" rel="noreferrer" className="bg-teal-700 text-white m-auto h-[40px] rounded-[6px] leading-[40px] px-[15px] flex items-center gap-[10px] w-[450px] justify-center">Dashboard RTMP <BsBoxArrowRight /></a> */}
            <form onSubmit={login} className="w-screen px-[20px] flex flex-col gap-[10px] sm:w-[450px] sm:my-[20px] sm:bg-white sm:shadow sm:p-[40px] sm:rounded-[6px]">
                <h1 className="text-center text-teal-700 text-[25px]">
                    <span className="font-bold">VPN</span>
                </h1>

                {
                    dataForm.errorMessage.other ?
                        <div className="text-red-900 border border-red-900 bg-red-100 rounded-[6px] px-[15px] min-h-[40px] leading-[40px] mt-[10px] relative">
                            {dataForm.errorMessage.other}
                            <button onClick={removeErrorOther} className="absolute right-[5px] top-[5px]">
                                <BsX className="h-[30px] w-[30px]" />
                            </button>
                        </div>
                        : null
                }

                <div className="mt-[20px]">Username</div>
                <input type="text" name="username" ref={refUsername} onKeyUp={removeError} className="w-full h-[40px] border rounded-[6px] px-[15px]" />
                {dataForm.errorMessage.username && <div className="text-red-900">{dataForm.errorMessage.username}</div>}

                <div className="mt-[20px]">Password</div>
                <input type="password" name="password" ref={refPassword} onKeyUp={removeError} className="w-full h-[40px] border rounded-[6px] px-[15px]" />
                {dataForm.errorMessage.password && <div className="text-red-900">{dataForm.errorMessage.password}</div>}

                <button disabled={dataForm.processing} className="w-full block bg-teal-700 mt-[20px] text-white rounded-[6px] h-[40px] leading-[40px] text-center">
                    {dataForm.processing ? <span className="border-[2px] border-white border-l-white/0 animate-spin rounded-full block w-[20px] h-[20px] m-auto"></span> : <>Log In</>}
                </button>
            </form>
        </main>
    )
}

export default Login

const dataFormReducer = (dataForm, payload) => {
    switch (payload.action) {
        case "startProcessing": return { ...dataForm, processing: true }
        case "stopProcessing": return { ...dataForm, processing: false }
        case "addErrorMessage": return { ...dataForm, errorMessage: { ...dataForm.errorMessage, ...payload.value } }
        case "deleteAllErrorMessage": return { ...dataForm, errorMessage: {} }
        case "deleteSingleErrorMessage":
            let errorMessage = dataForm.errorMessage;
            delete errorMessage[payload.part]
            return { ...dataForm, errorMessage }
        default: throw Error("Unknown dataFormReducer action : " + payload.action)
    }
}

const initialDataForm = {
    processing: false,
    errorMessage: {
        username: "",
        password: "",
        other: ""
    }
}